/**
 * Stores build information. The string literals are replaces at compile time by `set_version.js`.
 */
export const buildInfo = {
    versionNumber: "0.1.0",
    versionName: "nightly 2024-03-30",
    versionChannel: "nightly",
    buildDate: "2024-03-30T00:05:09.123Z",
    commitHash: "d0e2483bc772d25c5eb7e6774392df44cfa3b88d",
};
